<template>
    <div class="d-flex justify-space-between" style="width:100%">
        <v-dialog
            v-model="dialog"
            width="1024"
        >
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="d-flex justify-space-between" style="width:100%">
                    <span>Peer Reviews</span> 
                    <span class="font-weight-medium" v-if="fullyRatedPeers"> Complete <v-icon color="success">{{mdiCheckCircleOutline}}</v-icon> </span>
                    <span class="font-weight-medium" v-if="partiallyRatedPeers"> Partial <v-icon color="warning">{{mdiMinusCircleOutline}}</v-icon> </span>
                    <span class="font-weight-medium" v-if="countPeerRated == 0">0 Reviews<v-icon color="error">{{mdiAlertCircleOutline}}</v-icon></span> 
                </div>
            </template>    

            <v-card>
                <v-card-title class="text-h6 grey lighten-2">
                    Peer Ratings given by {{studentName}} (as of now)
                </v-card-title>

                <v-tabs v-model="tab">
                    <v-tab  v-for="(c, idx) in criterias" :key="c.id"> {{criterias[idx].criteriaName}} </v-tab>
                </v-tabs>

                <v-card-text class="mt-3" v-if="teamData.data">
                    <div class="d-flex justify-space-between">      
                        <span class="subtitle-1 font-weight-medium"> {{weightage}} Marks</span>
                    </div>
                    <p>{{criteriaDescription}}</p>
                    <v-divider></v-divider>

                    <v-data-table
                        :items-per-page="itemsPerPage"
                        :headers="headers"
                        :items="studentEval"
                        hide-default-footer
                        :search="search"/>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="dialog = false"
                >
                    Close
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { mdiCheckCircleOutline, mdiMinusCircleOutline, mdiAlertCircleOutline } from '@mdi/js'
    import { fetchPeerAssessmentScoresAdmin } from '../../api/course'

    export default {
        props: {
            teamName: { type: String, default: null },
            rollNumber: { type: String, default: null },
            studentName: { type: String, default: null },
            subjectCourseId: { type: Number, default: null },
            fullyRatedPeers: { type: Number, default: false },
            partiallyRatedPeers: { type: Number, default: false },
            countPeerRated: { type: Number, default: false }
        },
        computed:{
            criterias(){
                return this.teamData.data
            },
            criteriaName(){
                if(this.criterias && this.criterias.length > 0){
                    return this.teamData.data[this.tab].criteriaName
                }

                return ''
            },
            criteriaDescription(){
                if(this.criterias && this.criterias.length > 0){
                    return this.teamData.data[this.tab].criteriaDescription
                }

                return ''
            },
            weightage(){
                return this.criterias && this.criterias.length > 0 ? this.teamData.data[this.tab].weightage : ''
            },
            studentEval(){
                return this.criterias && this.criterias.length > 0 ? JSON.parse(this.criterias[this.tab].teamMembers): []
            },
        },
        mounted(){ 
        },
        data () {
            return {
                dialog: false,
                tab: 0,
                mdiCheckCircleOutline, 
                mdiMinusCircleOutline, 
                mdiAlertCircleOutline,
                teamData: [],
                itemsPerPage: 20,
                search: '',
                headers: [
                    {
                        text: 'Student Name',
                        align: 'start',
                        value: 'name'
                    },
                    { text: 'Roll No.', value: 'rollNumber' },
                    { text: 'Score', value: 'score'}
                ],
            }
        },
        methods: {
            async fetchPeerReviewData(teamName, subjectCourseId, rollNumber){
                try{
                    const apiData = await fetchPeerAssessmentScoresAdmin({
                        teamName, 
                        courseSubjectId: subjectCourseId,
                        rollNumber
                    })
                    
                    this.teamData = JSON.parse(JSON.stringify(apiData))
                }
                catch(e){
                    if(e.code === 'ERR_BAD_REQUEST'){
                        this.$toast.open({
                        message: e.response.data.details || e.response.data.error.message,
                        type: 'error',
                        });
                    }
                    else{
                        this.$toast.open({
                        message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                        type: 'error',
                        });
                    }

                }
            },
        },
        watch:{
            dialog(n, o) {
                if(n) {
                    this.fetchPeerReviewData(this.teamName, this.subjectCourseId, this.rollNumber)
                }
            },
            numStudents(n, o){
                this.$nextTick(() => { // Make sure add student happens first.
                    this.fetchPeerReviewData(this.teamName, this.subjectCourseId, this.rollNumber)
                });
            }
        }
    }
</script>

<style>

  .v-data-table-header th {
    white-space: nowrap;
  }

</style>