<template>
    <div>
        <div class="d-flex justify-space-between">
            <v-dialog
                v-model="dialog"
                width="1024"
                v-if="showReviewDialog"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        small
                        outlined
                        fab
                        dark
                        color="primary"
                    >
                        <v-icon>
                            {{ mdiStar }}
                        </v-icon>
                    </v-btn>
                </template>    

                <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                        All Ratings as of now
                    </v-card-title>

                    <v-tabs v-model="tab">
                        <v-tab  v-for="(c, idx) in criterias" :key="c.id"> {{criterias[idx].criteriaName}} </v-tab>
                    </v-tabs>

                    <v-card-text class="mt-3" v-if="teamData.data">
                        <div class="d-flex justify-space-between">      
                            <span class="subtitle-1 font-weight-medium"> {{weightage}} Marks</span>
                        </div>
                        <p>{{criteriaDescription}}</p>
                        <v-divider></v-divider>

                        <v-data-table
                            :items-per-page="itemsPerPage"
                            :headers="headers"
                            :items="studentEval"
                            hide-default-footer
                            :search="search"/>

                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        text
                        @click="dialog = false"
                    >
                        Close
                    </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>

    </div>
</template>

<script>
    import { mdiStar } from '@mdi/js'
    import { fetchTeamMemberScoresAdmin } from '../../api/course'

    export default {
        props: {
            teamName: { type: String, default: null },
            subjectCourseId: { type: Number, default: null },
            numStudents: { type: Number, default: null },
            showReviewDialog: { type: Boolean, default: false }
        },
        computed:{
            criterias(){
                return this.teamData.data
            },
            criteriaName(){
                if(this.criterias && this.criterias.length > 0){
                    return this.teamData.data[this.tab].criteriaName
                }

                return ''
            },
            criteriaDescription(){
                if(this.criterias && this.criterias.length > 0){
                    return this.teamData.data[this.tab].criteriaDescription
                }

                return ''
            },
            weightage(){
                return this.criterias && this.criterias.length > 0 ? this.teamData.data[this.tab].weightage : ''
            },
            studentEval(){
                return this.criterias && this.criterias.length > 0 ? JSON.parse(this.criterias[this.tab].teamMembers): []
            },
        },
        mounted(){
            if(this.showReviewDialog){
                this.fetchPeerReviewData(this.teamName, this.subjectCourseId)
            }  
        },
        data () {
            return {
                dialog: false,
                tab: 0,
                notAllRatingsAreAvailable: true,
                mdiStar,
                teamData: [],
                itemsPerPage: 20,
                search: '',
                headers: [
                    {
                        text: 'Student Name',
                        align: 'start',
                        value: 'name'
                    },
                    { text: 'Roll No.', value: 'rollNumber' },
                    { text: 'Score', value: 'score'},
                    { text: 'Awarded By', value: 'awardedByName' },
                    { text: 'Awarded By Roll Number', value: 'awardedByRollNumber' },
                    { text: 'Rating Type', value: 'ratingType' },
                ],
            }
        },
        methods: {
            async fetchPeerReviewData(teamName, subjectCourseId){
                try{
                    const apiData = await fetchTeamMemberScoresAdmin({
                        teamName, 
                        courseSubjectId: subjectCourseId
                    })
                    
                    this.teamData = JSON.parse(JSON.stringify(apiData))
                    const numStudents = parseInt(this.numStudents);
                    const totalRatingsExpected = numStudents * (numStudents -1) * apiData.data.length; 
                    let numRatingsObtained = 0;

                    for(let i = 0; i < apiData.data.length; i++){
                        let criteria = apiData.data[i];
                        numRatingsObtained += parseInt(criteria.numRatingsGiven);
                    }

                    this.notAllRatingsAreAvailable = apiData.data.length && (totalRatingsExpected <= numRatingsObtained) ? false : true;
                }
                catch(e){
                    if(e.code === 'ERR_BAD_REQUEST'){
                        this.$toast.open({
                        message: e.response.data.details || e.response.data.error.message,
                        type: 'error',
                        });
                    }
                    else{
                        this.$toast.open({
                        message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                        type: 'error',
                        });
                    }

                    this.notAllRatingsAreAvailable = true;
                }
            },
        },
        watch:{
            dialog(n, o) {
                if(n) {
                    this.fetchPeerReviewData(this.teamName, this.subjectCourseId)
                }
            },
            numStudents(n, o){
                this.$nextTick(() => { // Make sure add student happens first.
                    if(this.showReviewDialog){
                        this.fetchPeerReviewData(this.teamName, this.subjectCourseId)
                    }
                });
            }
        }
    }
</script>

<style>

  .v-data-table-header th {
    white-space: nowrap;
  }

</style>