<template>
  <div>
      <v-row>
        <v-col  cols="12" md="6">
          <v-select
              clearable
              :items="courseSubjects"
              return-object
              item-value="id"
              item-text="desc"
              label="Select Module (For Capstone Project Teams)"
              solo
              v-model="selectedCourse"
              @change="fetchTeams"
              @click:clear="removeCourse"
          >
          </v-select>
        </v-col>

        <v-col  cols="6" md="3">
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dueDate"
                label="Due Date"
                readonly
                v-bind="attrs"
                v-on="on"
              >
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dueDate"
              @input="menu2 = false"
              @change="saveParameter()"
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col  cols="4" md="2">
          <v-switch
            v-model="isCalcKnee"
            inset
            :label="`Knee Method`"
            @change="saveParameter()"
          >
          </v-switch>
        </v-col>

        <v-col cols="2" md="1" class="d-flex justify-space-between">
          <info-dialog/>
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-icon @click="importFinalTeam()" class="mt-3" large color="primary" v-bind="attrs" v-on="on">
                {{mdiFileImportOutline}}
              </v-icon>
            </template>
            <span>Import Final Project Team</span>
          </v-tooltip>
        </v-col>

      </v-row>
      
      <v-row v-if="selectedCourse && selectedCourse.id">
        <v-col  cols="12" md="9" style="height: 90vh; overflow-y: scroll">
          <v-row>
            <v-col v-for="(team, idx) in teams" :key="team.teamName"
              cols="12"
              md="4"
            >
              <v-card>
                <div class="pt-1" :style="cardColor(idx)"></div>
                <v-card-title class="d-flex" >
                  {{team.teamName}} 
                </v-card-title>
                
                <v-divider></v-divider>

                <v-card-text class="d-flex justify-space-between">
                  <span>Team Score </span>
                  <v-chip
                    small
                    color="error"
                    v-if="team.penalty"
                  >
                    Penalized
                  </v-chip>
                  <span>
                    {{team.teamScore}}
                    <v-icon @click="scoreDialog(team.teamName, team.teamScore, team.penalty)">
                      {{mdiPencil}}
                    </v-icon>
                  </span>
                </v-card-text>

                <v-divider></v-divider>

                <v-card-text class="d-flex justify-space-between">
                  <all-reviews-dialog 
                    :numStudents="team.students.length"
                    :showReviewDialog="(team.students && team.students.length > 1)"
                    :teamName="team.teamName" 
                    :subjectCourseId="parseInt(selectedCourse.id)">
                  </all-reviews-dialog>
                  <student-scores-dialog
                    :teamScore="team.teamScore"
                    :teamPenalty="team.penalty"
                    :showReviewDialog="(team.students && team.students.length > 1)"
                    :teamName="team.teamName" 
                    :subjectCourseId="parseInt(selectedCourse.id)">
                  </student-scores-dialog>
                  <student-comments-dialog
                    :teamScore="team.teamScore"
                    :showReviewDialog="(team.students && team.students.length > 1)"
                    :teamName="team.teamName" 
                    :subjectCourseId="parseInt(selectedCourse.id)">
                  </student-comments-dialog>
                </v-card-text>

                <draggable :list="team.students" group="teams" @change="log(team.teamName, $event)" :disabled="disabled">
                  <v-card  class="mx-auto mb-2" v-for="student in team.students" :key="student.id" ripple elevation="2">
                    <v-card-text>
                      <div>{{student.rollNumber}} {{student.isFemale | gender}}</div>
                      <div class="text--primary">
                        {{student.name}}
                      </div>
                      <v-divider class="mt-2 mb-2"></v-divider>
                        <v-row>
                          <v-col class="mt-4">
                            Additionally penalize student [Penalty %].
                          </v-col>
                          
                          <v-col md="3">
                            <v-text-field
                              label="Penalty %"
                              hide-details 
                              single-line 
                              type="number"
                              v-model="student.penalty"
                              @change="updateStudentPenalty(student.penalty, student.rollNumber, team.teamName)"
                              @click="updateStudentPenalty(student.penalty, student.rollNumber, team.teamName)"
                            >
                            </v-text-field>
                          </v-col>
                        </v-row>
                      <v-divider class="mt-2 mb-2"></v-divider>
                      <peer-ratings-dialog
                        :studentName="student.name"
                        :fullyRatedPeers="student.fullyRatedPeers"
                        :partiallyRatedPeers="student.partiallyRatedPeers"
                        :countPeerRated="student.countPeerRated"
                        :teamName="team.teamName" 
                        :rollNumber="student.rollNumber"
                        :subjectCourseId="parseInt(selectedCourse.id)"
                      >
                      </peer-ratings-dialog>
                      <self-ratings-dialog
                        :studentName="student.name"
                        :fullyRatedSelf="student.fullyRatedSelf"
                        :partiallyRatedSelf="student.partiallyRatedSelf"
                        :countSelfRated="student.countSelfRated"
                        :teamName="team.teamName" 
                        :rollNumber="student.rollNumber"
                        :subjectCourseId="parseInt(selectedCourse.id)"
                      >
                      </self-ratings-dialog>
                    </v-card-text>
                  </v-card>
                </draggable>

              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col style="height: 90vh; overflow-y: scroll">
          <v-sheet v-if="selectedCourse.id">
            <h3 class="pa-3 mb-2" >Unassigned Students</h3>
          </v-sheet>
          <draggable :list="unassignedStudents" group="teams" @change="log" :disabled="disabled">
            <v-card  class="mx-auto" v-for="student in unassignedStudents" :key="student.id" ripple elevation="2">
              <v-card-text>
                <div>{{student.rollNumber}} {{student.isFemale | gender}} </div>
                <div class="text--primary">
                  {{student.name}}
                </div>
              </v-card-text>
            </v-card>
          </draggable>
        </v-col>
      </v-row>

      <v-dialog
        v-model="dialog"
        max-width="800"
      >
        <v-card>
          <v-card-title class="text-h5">
            Assign / Update Marks for {{selectedTeam}}
          </v-card-title>

          <v-card-text class="d-flex justify-space-between">
            <v-row>
              <v-col class="mt-4">
                Kindly enter the team score here and click on update.
              </v-col>
              
              <v-col md="3">
                <v-text-field
                  label="Score"
                  hide-details 
                  single-line 
                  type="number"
                  v-model="teamScore"
                >
                </v-text-field>
              </v-col>
            </v-row>

          </v-card-text>

          <v-card-text class="d-flex justify-space-between">
            <v-row>
              <v-col class="mt-4">
                Additionally penalize this team for any malpractice. [Penalty %]
              </v-col>
              
              <v-col md="3">
                <v-text-field
                  label="Penalty %"
                  hide-details 
                  single-line 
                  type="number"
                  v-model="teamPenalty"
                >
                </v-text-field>
              </v-col>
            </v-row>

          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              class="mr-4"
              @click="dialog = false"
            >
              Close
            </v-btn>

            <v-btn
              class="ml-4"
              color="primary"
              @click="updateTeamScore"
            >
              Update
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import { mdiCheckCircleOutline, mdiMinusCircleOutline, mdiAlertCircleOutline, mdiPencil, mdiFileImportOutline } from '@mdi/js'
import draggable from "vuedraggable";
import { getRandomColor } from "../../utils/colors";
import {  fetchStudentTeams, 
          fetchStudentWithoutTeams, 
          addStudentToTeam, 
          deleteStudentFromTeam, 
          fetchCapstoneParameter, 
          mapProjectTeamToCapstone,
          saveGroupTeamScore,
          saveGroupStudentPenalty,
          saveGroupAssignmentParameter } from '../../api/course';
import AllReviewsDialog from './AllReviewsDialog.vue';
import StudentScoresDialog from '../teams/StudentScoresDialog.vue';
import SelfRatingsDialog from '../teams/SelfRatingsDialog.vue';
import PeerRatingsDialog from '../teams/PeerRatingsDialog.vue';
import StudentCommentsDialog from '../teams/StudentCommentsDialog.vue'; 
import InfoDialog from '../../components/InfoDialog.vue'

let _timerId;

export default {
  components: {
    draggable,
    AllReviewsDialog,
    StudentScoresDialog,
    SelfRatingsDialog,
    PeerRatingsDialog,
    StudentCommentsDialog,
    InfoDialog
  },
  data() {
    return {
      selectedCourse: {},
      teams:[],
      unassignedStudents: [],
      isCalcKnee: false,
      dueDate: null,
      menu2: false,
      dialog: false,
      teamPenalty: 0,
      mdiCheckCircleOutline,
      mdiMinusCircleOutline,
      mdiAlertCircleOutline,
      mdiFileImportOutline,
      mdiPencil,
      selectedTeam: '',
      teamScore: 0,
    }
  },
  filters: {
    gender: function (value) {
      if (!value) return '(M)'
      return '(F)'
    }
  },
  computed: {
    courseSubjects(){    
        let courseSubjects = [];
        
        for(let i = 0; i < this.$store.getters.getCourseSubjects.length; i++){
            let courseSubject = {}
            let current = this.$store.getters.getCourseSubjects[i]

            courseSubject['desc'] = `[${current.subject.subjectName}] ${current.subject.subjectDescription}`
            courseSubject['id'] = current.id

            courseSubjects.push(courseSubject)
        }
        
        return courseSubjects
    },
    disabled(){

      if(this.dueDate){
        let dueDate = new Date(this.dueDate)
        dueDate.setDate(dueDate.getDate() + 1);

        const dueTime = dueDate.getTime()
        const today = new Date().getTime()
        
        // console.log(dueDate,today) // Uncomment for DEBUG.

        if(dueTime >= today){
          return false
        }
        else{
          return true
        }
      }

      return false
    }
  },
  methods:{
    scoreDialog(selectedTeam, teamScore, penalty) {
      this.dialog = !this.dialog
      this.penalty = penalty
      this.teamPenalty = penalty
      if(teamScore && Number.isInteger(parseInt(teamScore)) ){
        this.teamScore = teamScore
      }
      else{
        this.teamScore = 0
      }
      this.selectedTeam = selectedTeam
    },
    cardColor(idx){
      return { background : getRandomColor(idx) }
    },
    log: function(teamName, evt) {
      if(evt && evt.added){
        this.addToTeam(evt.added, teamName)
      }

      if(evt && evt.removed){
       this.deleteFromTeam(evt.removed)
     }
    },
    async _updateStudentPenalty(penalty, rollNumber, teamName){
      try{
        const params = {
          penalty: penalty ? penalty : 0, 
          teamName, 
          rollNumber,
          courseSubjectId: this.selectedCourse.id,
        }
        await saveGroupStudentPenalty(params)
      }
      catch(e){
        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
      }
    },
    updateStudentPenalty(penalty, rollNumber, teamName) {
        if (_timerId) 
          clearTimeout(_timerId); 

        _timerId = setTimeout(() => {
          this._updateStudentPenalty(penalty, rollNumber, teamName)
        }, 200); // delay
    },
    async updateTeamScore(){

      let params = {
        teamName : this.selectedTeam,
        teamScore: this.teamScore,
        courseSubjectId: this.selectedCourse.id,
        penalty: this.teamPenalty ? this.teamPenalty : 0
      }

      try{
        await saveGroupTeamScore(params)

        this.$toast.open({
          message: 'Team Score updated.',
          type: 'success',
        });

        this.dialog = false
        this.fetchTeams()
      }
      catch(e){
        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });

          this.dialog = false
        }
      }
    },
    async importFinalTeam(){
      try{
        await mapProjectTeamToCapstone({courseSubjectId: this.selectedCourse.id, dueDate: this.dueDate});
        this.fetchTeams()
      }
      catch(e){
        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
      }
    },
    async fetchTeams(){
      if( this.selectedCourse && this.selectedCourse.id ){
        try{
          const studentTeams = await fetchStudentTeams({courseSubjectId: this.selectedCourse.id })
          const params = await fetchCapstoneParameter({courseSubjectId: this.selectedCourse.id })

          if(params && params.data){
            this.isCalcKnee = params.data.isCalcKnee
            this.dueDate = params.data.dueDate
          }
          else{
            this.isCalcKnee = true
            this.dueDate = null
          }

          let cleanData = []
          
          for(let i = 0; i < studentTeams.data.length; i++){
            let data = studentTeams.data[i]
            let students = JSON.parse(data.students)
            
            if(students[0].id){
              data.students= students
            }
            else{
              data.students= []
            }

            cleanData.push(data)
          }

          this.teams = cleanData
        }
        catch(e){
          if(e.code === 'ERR_BAD_REQUEST'){
            this.$toast.open({
              message: e.response.data.details || e.response.data.error.message,
              type: 'error',
            });
          }
          else{
            this.$toast.open({
              message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
              type: 'error',
            });
          }
        }
        this.studentsWithoutTeams()
      }
    },
    async studentsWithoutTeams(){
      if( this.selectedCourse && this.selectedCourse.id ){
        const studentTeams = await fetchStudentWithoutTeams({courseSubjectId: this.selectedCourse.id })
        this.unassignedStudents = studentTeams.data
      }
    },
    removeCourse(){
      this.teams = []
      this.unassignedStudents = []
    },
    async addToTeam(student, teamName){

      let params = {
        rollNumber : student.element.rollNumber,
        courseSubjectId: this.selectedCourse.id,
        teamName: teamName
      }

      await addStudentToTeam(params)
    },
    async deleteFromTeam(student){
      let params = {
        rollNumber : student.element.rollNumber,
        courseSubjectId: this.selectedCourse.id
      }

      await deleteStudentFromTeam(params)
    },
    async saveParameter(){

      let params = {
        dueDate : this.dueDate,
        isCalcKnee: this.isCalcKnee,
        courseSubjectId: this.selectedCourse.id
      }

      if(!this.dueDate){
        this.$toast.open({
          message: 'Kindly enter a due date for the peer / self review.',
          type: 'error',
      });
        return;
      }

      try{
        await saveGroupAssignmentParameter(params)

        this.$toast.open({
          message: 'Capstone Project parameters updated.',
          type: 'success',
        });
      }
      catch(e){
        if(e.code === 'ERR_BAD_REQUEST'){
          this.$toast.open({
            message: e.response.data.details || e.response.data.error.message,
            type: 'error',
          });
        }
        else{
          this.$toast.open({
            message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
            type: 'error',
          });
        }
      }
    },
  },
}
</script>
