<template>
    <div class="d-flex justify-space-between" style="width:100%">
        <v-dialog
            v-model="dialog"
            width="1024"
        >
            <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on" class="d-flex justify-space-between" style="width:100%">
                    <span>Self Reviews</span> 
                    <span class="font-weight-medium" v-if="fullyRatedSelf"> Complete <v-icon color="success">{{mdiCheckCircleOutline}}</v-icon> </span>
                    <span class="font-weight-medium" v-if="partiallyRatedSelf"> Partial <v-icon color="warning">{{mdiMinusCircleOutline}}</v-icon> </span>
                    <span class="font-weight-medium" v-if="countSelfRated == 0">0 Reviews<v-icon color="error">{{mdiAlertCircleOutline}}</v-icon></span> 
                </div>
            </template>    

            <v-card>
                <v-card-title class="text-h6 grey lighten-2">
                    Self Ratings for {{studentName}} (as of now)
                </v-card-title>
                
                <v-card-text>
                    <v-data-table
                        :items-per-page="itemsPerPage"
                        :headers="headers"
                        :items="teamData.data"
                        hide-default-footer
                        :search="search"/>

                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="dialog = false"
                >
                    Close
                </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { mdiCheckCircleOutline, mdiMinusCircleOutline, mdiAlertCircleOutline } from '@mdi/js'
    import { fetchSelfAssessmentScoresAdmin } from '../../api/course'

    export default {
        props: {
            teamName: { type: String, default: null },
            rollNumber: { type: String, default: null },
            studentName: { type: String, default: null },
            subjectCourseId: { type: Number, default: null },
            fullyRatedSelf: { type: Number, default: false },
            partiallyRatedSelf: { type: Number, default: false },
            countSelfRated: { type: Number, default: false }
        },
        mounted(){  
        },
        data () {
            return {
                dialog: false,
                mdiCheckCircleOutline, 
                mdiMinusCircleOutline, 
                mdiAlertCircleOutline,
                teamData: [],
                itemsPerPage: 20,
                search: '',
                headers: [
                    { text: 'Roll No.', value: 'rollNumber' },
                    { text: 'Rating', value: 'score'},
                    { text: 'Criteria', value: 'criteriaName' },
                    { text: 'Criteria Description', value: 'criteriaDescription' },
                ],
            }
        },
        methods: {
            async fetchSelfReviewData(teamName, subjectCourseId, rollNumber){
                try{
                    const apiData = await fetchSelfAssessmentScoresAdmin({
                        teamName, 
                        courseSubjectId: subjectCourseId,
                        rollNumber
                    })
                    
                    this.teamData = JSON.parse(JSON.stringify(apiData))
                }
                catch(e){
                    if(e.code === 'ERR_BAD_REQUEST'){
                        this.$toast.open({
                        message: e.response.data.details || e.response.data.error.message,
                        type: 'error',
                        });
                    }
                    else{
                        this.$toast.open({
                        message: 'Unknown error. We are unable to process your request at this time. Please get in touch with the admin to get the issue resolved.',
                        type: 'error',
                        });
                    }
                }
            },
        },
        watch:{
            dialog(n, o) {
                if(n) {
                    this.fetchSelfReviewData(this.teamName, this.subjectCourseId, this.rollNumber)
                }
            }
        }
    }
</script>

<style>

  .v-data-table-header th {
    white-space: nowrap;
  }

</style>